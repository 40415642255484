/*A number of these stylings were brought in from the projetc template*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  height: 100%;
  min-height: 100svh;
  overflow: hidden;
}

/* Otherwise, use `::-webkit-scrollbar-*` pseudo-elements */

.dark .main-container {
  background-color: black;
  color: white;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: calc((var(--vh, 1vh) * 100) - 86px);
  overflow-y: scroll;
  position: relative;
  scrollbar-color: rgb(155, 155, 155) transparent;

  &.no-pointer-events {
    pointer-events: none;
  }
}

.main-container.rhinestone-mockup {
  height: 100vh;
}

/* styles.css or a similar stylesheet */
.smooth-transition {
  transition: margin-top 0.5s ease;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#react-root {
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: rgb(229 231 235);
}

.animate-in {
  animation: animateIn 0.3s ease 0.15s both;
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.alert {
  background-color: #ff4545;
  margin: 50px;
}

input {
  margin: 5px;
  margin-bottom: 10px;
}

.log-in,
.log-out,
.register {
  background: linear-gradient(127deg, #a759d1 0%, #8261ee 91%);
  border-radius: 4px;
  color: white;
  width: 30%;
  height: 3em;
  border: none;
  transition: 0.2s;
  margin-inline: auto;
  margin-top: 1em;
}

.log-in:hover,
.log-out:hover,
.register:hover {
  background: linear-gradient(127deg, #ba8cd3 0%, #a38ee8 91%);
  transition: 0.2s;
  cursor: pointer;
}

/* This line makes the "Login" button look like links */
.link-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: 0;
  border-bottom: 1px solid #444;
  cursor: pointer;
}

/*styles the upload button to look like the other material UI buttons*/
.picUploader {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5%;
  padding: 10px;
  font: inherit;
  outline: 0;
  cursor: pointer;
}

/*swaps the buttons in sweetalerts2*/
.confirm {
  margin: 0 !important;
  flex-direction: row-reverse;
}

/*matches hover activity in Material UI*/
.picUploader:hover {
  background-color: #388e3c;
  color: white;
  border: none;
  border-radius: 5%;
  padding: 10px;
  font: inherit;
  outline: 0;
  cursor: pointer;
}

/* Used for tooltips */
#tooltip {
  /* position: relative;  */
  display: inline-block;
}

/* Tooltip text */
#tooltip #tooltiptext {
  visibility: hidden;
  width: 65%;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid black;

  /* Position the tooltip text*/
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
#tooltip:hover #tooltiptext {
  visibility: visible;
}

/*sticks the navigation bar at the top*/
#Nav {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99998;
}

.borderBottom {
  border-bottom: solid black 1px;
}

.fourfour {
  text-align: center;
}

.btn-primary {
  color: #fff;
  background-color: #8261ee;
  border-color: #a759d1;
}

.btn-primary:hover {
  background-color: #a759d1;
  border-color: #8261ee;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-wed0tz {
  background-color: #8261ee;
  border-color: #a759d1;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-wed0tz:hover {
  background-color: #a759d1;
  border-color: #8261ee;
}

tbody tr:nth-child(2n) {
  background-color: #e5e1f5e7;
}

/* tbody tr:nth-child(2n):hover {
  background-color: #d5ccf3e7;
} */

/* DECOQUEUE CSS */

/*A number of these stylings were brought in from the projetc template*/

input {
  margin: 5px;
  margin-bottom: 10px;
}

/*swaps the buttons in sweetalerts2*/
.confirm {
  margin: 0 !important;
  flex-direction: row-reverse;
}

.multiButtons {
  display: flex;
  align-items: flex-start;
  margin: 50px 50px 50px 0;
  position: fixed;
  left: 2%;
  top: 11%;
  z-index: 100000;
  background-color: white;
  padding: 0.5em 0.5em 0.5em 0;
  border-radius: 15px;
}

.multiButtons Button {
  margin: 5px;
}

.queue-container {
  background-image: linear-gradient(127deg, #a759d1 0%, #8261ee 71%);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 6.3rem;
  z-index: 999999;
}

.queue-nav-link,
.queue-active-nav-link {
  color: #e9cbff43 !important;
  padding: 0.5em;
  transition: color 1.3s linear;
  margin: 0 6em 0 6em;
}

.queue-active-nav-link {
  color: white !important;
}

.queue-nav-link:hover,
.queue-active-nav-link:hover {
  color: #ddc4d5;
}

.select-all {
  background-color: #fff;
}

.queue-container-return {
  height: 100vh;
  overflow: hidden;
}

.goBack {
  position: absolute;
  top: 1em;
  right: 1em;
}

.setAmount {
  height: 3.5em;
}

.editQuantityHeader {
  margin-top: 1.5em;
}

.editQuantity {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.infoButton {
  background-color: transparent;
  border: none;
  height: auto;
  width: auto;
}

.infoIcon {
  filter: invert(60%);
  transition: filter 1s;
}

.infoIcon:hover {
  filter: invert(40%);
}

.infoButton:hover,
.infoButton:active {
  background-color: transparent;
  border: none;
}

iframe {
  display: none;
}

h1 {
  font-weight: bold;
  margin-inline: auto;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
}

@media (max-width: 764px) {
  h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
