body {
  background-color: #c0c0c0;
}

table {
  width: 100%;
}

td {
  padding: 2%;
}

thead {
  background-color: rgb(172, 172, 172);
}

tr {
  margin: 10%;
}

tbody tr:nth-child(even) {
  background-color: #a3c0ff;
}

br {
  height: 1500%;
}

form {
  text-align: center;
  display: flex;
  justify-content: center;
}

.reseller-form {
  text-align: center;
  margin-top: 20px;
}

.sanmar-form {
  display: flex;
  text-align: center;
  margin-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-around;
}

.sanmar-row {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
}

.capture-form {
  text-align: center;
  margin-top: 20px;
  background-color: #e0a7ff86;
  padding: 20px;
}

.clothing-importer {
  margin: 20px;
}

.clothing-data {
  margin: 10px;
}

.tracking-data {
  margin: 50px;
}

.clothing-input {
  text-align: center;
  width: 100px;
  border-radius: 10px;
  margin: 10px;
}

.total-form {
  display: flex;
  justify-content: right;
}

.sales-form {
  background-color: #ffffff;
  padding: 30px;
}

.order-input {
  text-align: center;
  width: 100px;
  border-radius: 10px;
  margin: 10px;
}

.big-number {
  margin: 20px;
}

.sales-input {
  text-align: center;
  width: 200px;
  border-radius: 10px;
  margin: 10px;
  background-color: rgb(103, 86, 255);
}

.loader {
  border: 66px solid rgb(172, 172, 172);
  border-radius: 50%;
  border-top: 66px solid #a759d1d6;
  width: 420px;
  height: 420px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ol-map,
#map,
.map {
  min-width: 600px;
  min-height: 500px;
  margin-right: 100px;
  margin-left: 100px;
  height: 1000px;
  width: "100%";
}

.container {
  padding: 20px;
  text-align: center;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-full-screen {
  top: 0.5em;
  right: 0.5em;
}

.invis {
  display: none;
}

#sanmar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MuiPaper-rounded {
  border-radius: 30px;
}

.MuiPaper-root {
  margin: 1%;
}

.MuiTableCell-root {
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.jss41 {
  display: none;
}

.MuiTableCell-root.MuiTableCell-head.MUIDataTableSelectCell-root-35.MUIDataTableSelectCell-fixedHeader-36.MUIDataTableSelectCell-fixedLeft-37.MUIDataTableSelectCell-headerCell-41.MuiTableCell-paddingCheckbox {
  display: none;
}

.jss45 {
  display: none;
}

.formPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-inputs {
  display: flex;
  justify-content: space-evenly;
}

.form-group {
  margin: 20px 10px 0 10px;
}
