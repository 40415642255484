/* .MuiPaper-root {
  max-width: 1400px;
  width: 90%;
  margin: auto;
  padding: 1em;
} */

.MuiTableContainer-root {
  width: 100%;
}

.MuiTableHead-root {
  background-color: white;
  height: 30px;
}

.status-container {
  width: 46%;
  display: flex;
  justify-content: space-between;
}

.checkbox-input {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.MuiTableCell-body,
.MuiTableCell-root {
  height: 30px !important;
}

.actions-options {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1.5em;
}

.top-table-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.supacolor-info-icon {
  font-size: 30px;
}

.img-upload-close-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  padding: 0px;
  transition: 0.3s;
}

.img-upload-x {
  color: black;
  font-size: 40px;
  transition: 0.3s;
}

.img-upload-x:hover {
  color: red;
}

.MuiButton-label {
  display: flex !important;
  justify-content: flex-start !important;
  width: 100%;
}

.MuiTableCell-root {
  padding: 0;
  padding-block: 1em;
}

.orderNumber-btn {
  background-color: transparent;
  border: none;
}

.orderNumber-btn:hover,
.modal-orderNumber:hover {
  cursor: pointer;
  color: rgb(103, 86, 255);
}

.order-items-container {
  height: 25em;
  width: 100%;
  overflow-x: scroll;
}

.uploadImages-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  width: 95%;
  padding: 1em;
  margin: 0.5em 0;
}
@media screen and (max-width: 760px) {
  .uploadImages-container {
    flex-direction: column;
    width: 95%;
  }
}

.file-input {
  width: 15em;
}

.ref-input {
  display: none;
}

.inputs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}
@media screen and (max-width: 760px) {
  .inputs-container {
    height: 90%;
  }
}

.job-canceled {
  background-color: rgb(133, 133, 133);
}

.needs-artwork,
.artwork-uploaded,
.job-canceled {
  color: white;
  width: 160px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
}

.needs-artwork {
  background-color: red;
}

.cancelled {
  background-color: gray;
}

.artwork-uploaded {
  background-color: green;
}

.details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 85%;
  margin-top: 1em;
}

@media screen and (max-width: 760px) {
  .image-form {
    height: 80%;
  }
}

.modal-styles {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 95%;
  height: 40em;
  max-width: 40em;
  padding: 1em 2em;
  top: 50px;
  position: relative;
}

.details-modal-styles {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 95%;
  height: 40em;
  max-width: 40em;
  padding: 1em 2em;
  top: 50px;
  position: relative;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 49%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.5em;
  height: 13em;
  border-radius: 5px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-content p {
  text-align: center;
  width: 90%;
}

.popup-button {
  width: 70%;
  color: white;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.52);
  transition: 0.3s;
}

.success-btn {
  background-color: green;
}

.error-btn {
  background-color: red;
}

.popup-checkmark {
  width: 75px;
  height: 75px;
}

.success {
  color: green;
}

.error {
  color: red;
}

.success-btn:hover {
  background-color: rgb(0, 108, 0);
  cursor: pointer;
}

.error-btn:hover {
  background-color: rgb(174, 0, 0);
  cursor: pointer;
}

.upload-items-container {
  /* background-color: #a3c0ff; */
  height: 27em;
  width: 100%;
  overflow-x: scroll;
}

.supacolor-table-head {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.supacolor-info-icon {
  margin-right: 1em;
  margin-bottom: 5px;
  transition: 0.2s;
  color: rgb(164, 164, 164);
  z-index: 9999;
}
.supacolor-info-icon:hover {
  cursor: pointer;
  scale: 105%;
  color: gray;
}
