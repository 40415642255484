@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-menu h4 {
  color: #30303080;
  z-index: 50;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  /* margin-left: -30em; */
  /* border-left: 1px solid #303030; */
  z-index: 49;
  position: fixed;
}

.circle {
  height: 70em;
  width: 60em;
  /* border-radius: 50% 0 0 50%; */
  position: fixed;
  top: -5em;
  right: 0;
  z-index: 1;
  background-image: linear-gradient(0deg, #ffffff39 20%, #8447ff4e 71%);
  clip-path: polygon(83% 0, 100% 0%, 100% 100%, 18% 100%);
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -295deg,
    #a759d1 0%,
    #8261ee 29%,
    #ff1362 67%,
    #fc4242 80%,
    #a759d1 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  font-size: 8em;
  font-weight: bolder;
  margin-bottom: 0.5em;
  text-align: left;
  line-height: 1.1em;
  z-index: 50;
  /* position: fixed; */
}

.animate-bg {
  background-image: linear-gradient(
    -295deg,
    #a759d1 0%,
    #8261ee 20%,
    #fc4242 50%,
    #8261ee 80%,
    #a759d1 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  animation: textclip 5s linear infinite;
  /* position: fixed; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@media only screen and (max-width: 1390px) {
  .animate-charcter {
    font-size: 6em;
  }

  .info {
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .animate-charcter {
    font-size: 3em;
  }
}
